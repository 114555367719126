<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="4">
          <h1>Property Matching</h1>
        </v-col>
        <v-col cols="5">
          <v-text-field
            label="Search Property Matching"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="3" align="right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                light
                class="mr-2"
                v-on="on"
                :to="{
                  name: 'module-melrose-properties-property-matching-archived',
                }"
              >
                <v-icon small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>View Archived Property Matching</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="accent"
                v-on="on"
                @click="$refs.matchingDialog.open()"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Property Matching</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="property_matching"
        class="mt-4"
        no-data-text="No Property Matching found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                @click="openSubmissionDialog(item)"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
              >
                <v-icon x-small>mdi-email</v-icon>
              </v-btn>
            </template>
            <span>Email</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteMatching(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog
      v-model="submissionDialog.open"
      scrollable
      max-width="1000"
      @click:outside="resetSubmissionDialog()"
    >
      <v-card>
        <v-card-title class="headline">View Submission</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-simple-table>
            <tbody>
              <tr>
                <th>Full Name</th>
                <td>{{ submissionDialog.submission.full_name }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ submissionDialog.submission.email }}</td>
              </tr>
              <tr
                v-for="(input, name) in submissionDialog.submission.content"
                :key="name"
              >
                <th>{{ name }}</th>
                <td
                  style="padding-top: 8px; padding-bottom: 8px"
                  v-html="input"
                ></td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table class="mt-5">
            <tbody>
              <tr>
                <th>Submitted At</th>
                <td>
                  {{ submissionDialog.submission.formatted_dates.created_at }}
                </td>
              </tr>
              <tr>
                <th>Form Name</th>
                <td>{{ submissionDialog.submission.form_name }}</td>
              </tr>
              <tr>
                <th>IP Address</th>
                <td>{{ submissionDialog.submission.ip_address }}</td>
              </tr>
              <tr>
                <th>Page URL</th>
                <td>/{{ submissionDialog.submission.page_url }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetSubmissionDialog()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteMatching.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Property Matching</v-card-title>
        <v-card-text>Are you sure you wish to archive ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteMatching"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteMatching.loading"
            @click="saveDeleteMatching"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <matching-dialog ref="matchingDialog" />
  </div>
</template>

<script>
import MatchingDialog from "./components/MatchDialog.vue";

export default {
  components: { MatchingDialog },

  data() {
    return {
      searchTerm: "",
      searchFormName: "",
      deleteMatching: {
        dialog: false,
        matching: {},
        loading: false,
      },
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          exact: true,
          to: { name: "module-melrose-properties-all" },
        },
        {
          text: "Property Matching",
          disabled: true,
        },
      ],
      submissionDialog: {
        open: false,
        submission: {
          full_name: null,
          email: null,
          content: [],
          form_name: null,
          page_url: null,
          formatted_dates: {
            created_at: null,
          },
        },
      },
      resetSubmissionDialog() {
        this.submissionDialog.open = false;
        this.submissionDialog.submission = {
          full_name: null,
          email: null,
          content: [],
          form_name: null,
          page_url: null,
          formatted_dates: {
            created_at: null,
          },
        };
      },
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Property Type", value: "property_type" },
        { text: "Price Min", value: "min_price" },
        { text: "Price Max", value: "max_price" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    property_matching() {
      let submissions = this.$store.state.contactForms["submissions"];

      submissions = submissions.filter((slot) => {
        return slot.form_name == "matching-property-form";
      });

      submissions.forEach((slot) => {
        slot.min_price = slot.content["Min Price"];
        slot.max_price = slot.content["Max Price"];
        slot.property_type = slot.content["Property Type"];
      });

      if (this.searchTerm !== "") {
        submissions = submissions.filter((s) => {
          const name = s.full_name.toLowerCase();
          const email = s.email.toLowerCase();
          const formName = s.form_name.toLowerCase();
          const pageUrl = s.page_url.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            name.includes(searchTerm) ||
            email.includes(searchTerm) ||
            formName.includes(searchTerm) ||
            pageUrl.includes(searchTerm)
          );
        });
      }

      return submissions;
    },
  },

  methods: {
    openSubmissionDialog(submission) {
      this.submissionDialog.submission = submission;
      this.submissionDialog.open = true;
    },

    openDeleteMatching(matching) {
      this.deleteMatching.matching = matching;
      this.deleteMatching.dialog = true;
    },

    resetDeleteMatching() {
      this.deleteMatching.dialog = false;
      this.deleteMatching.matching = {};
      this.deleteMatching.loading = false;
    },

    saveDeleteMatching() {
      this.deleteMatching.loading = true;

      this.$store
        .dispatch("contactForms/deleteSubmission", {
          appId: this.$route.params.id,
          submissionId: this.deleteMatching.matching.id,
        })
        .then(() => {
          this.resetDeleteMatching();
        })
        .catch(() => {
          this.deleteMatching.loading = false;
        });
    },
  },
};
</script>
