<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Matching
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="matching-form">
          <v-select
            label="Locations"
            v-model="fields.locations"
            :items="locations"
            outlined
            multiple
            background-color="white"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          ></v-select>

          <v-select
            label="Min Price"
            v-model="fields.min_price"
            :items="prices"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('min_price')"
            :error-messages="errors['min_price']"
          ></v-select>

          <v-select
            label="Max Price"
            v-model="fields.max_price"
            :items="prices"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('max_price')"
            :error-messages="errors['max_price']"
          ></v-select>

          <v-select
            label="Property Type"
            v-model="fields.property_type"
            :items="types"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_type')"
            :error-messages="errors['property_type']"
          ></v-select>

          <v-textarea
            label="General Comments"
            v-model="fields.comments"
            rows="5"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('comments')"
            :error-messages="errors['comments']"
          ></v-textarea>

          <v-text-field
            label="Full Name"
            v-model="fields.full_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('full_name')"
            :error-messages="errors['full_name']"
          ></v-text-field>

          <v-text-field
            label="Email"
            v-model="fields.email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>

          <v-text-field
            label="Phone Number"
            v-model="fields.phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>

          <v-text-field
            label="Address"
            v-model="fields.address"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address')"
            :error-messages="errors['address']"
          ></v-text-field>

          <v-text-field
            label="Address Line 2"
            v-model="fields.address_2"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_2')"
            :error-messages="errors['address_2']"
          ></v-text-field>

          <v-select
            label="Preferred Contact Method"
            v-model="fields.contact_method"
            :items="contactMethods"
            outlined
            background-color="white"
            :disabled="editingFromProperty"
            :error="errors.hasOwnProperty('contact_method')"
            :error-messages="errors['contact_method']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="matching-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    editingFromProperty: {
      type: Boolean,
      default: false,
      required: false,
    },
    editingFromCustomer: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      offer: {},
      fields: {
        locations: null,
        contact_method: null,
        address_2: null,
        address: null,
        phone: null,
        email: null,
        full_name: null,
        comments: null,
        min_price: null,
        max_price: null,
        property_type: null,
      },
      errors: {},
      contactMethods: ["Any", "Email", "Phone"],
      types: [
        "House",
        "Flat/Apartment",
        "Bungalow",
        "Land",
        "Commercial Property",
        "Other",
      ],

      locations: [
        "Chirnside",
        "Coldingham",
        "Coldstream",
        "Duns",
        "Eyemouth",
        "Kelso",
      ],

      prices: [
        "25000",
        "50000",
        "75000",
        "100000",
        "150000",
        "200000",
        "300000",
        "500000",
        "750000",
        "1000000",
      ],
    };
  },

  methods: {
    open(offer = null) {
      if (offer !== null) {
        this.offer = offer;
        this.isEditing = true;
      } else {
        const propertyId = this.$route.params.propertyId;
        if (propertyId) {
          this.fields.property_id = parseInt(this.$route.params.propertyId);
        }
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.$store
        .dispatch("contactForms/saveMatching", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.offer = {};
      this.fields = {
        locations: null,
        contact_method: null,
        address_2: null,
        address: null,
        phone: null,
        email: null,
        full_name: null,
        comments: null,
        min_price: null,
        max_price: null,
        property_type: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },

  computed: {
    customers() {
      return this.$store.getters["melrose/customersStore/all"];
    },

    properties() {
      return this.$store.getters["melrose/propertiesStore/all"];
    },

    solicitors() {
      return this.$store.getters["melrose/solicitorsStore/all"];
    },
  },
};
</script>
